@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 0 0% 98%;
    --foreground: 30 8% 5%;

    --card: 0 8% 97%;
    --card-foreground: 30 8% 5%;

    --popover: 0 8% 97%;
    --popover-foreground: 30 8% 5%;

    --primary: 42 91% 57%;
    --primary-foreground: 0 0% 2%;

    --secondary: 60 6% 93%;
    --secondary-foreground: 30 8% 5%;

    --muted: 60 6% 93%;
    --muted-foreground: 39 6% 45%;

    --accent: 60 6% 93%;
    --accent-foreground: 30 8% 5%;

    --destructive: 0 84% 60%;
    --destructive-foreground: 0 0% 98%;

    --border: 40 6% 90%;
    --input: 40 7% 82%;
    --ring: 41 24% 74%;

    --radius: 0.5rem;
  }

  .dark {
    --background: 40 7% 8%;
    --foreground: 60 9% 98%;

    --card: 40 6% 10%;
    --card-foreground: 60 9% 98%;

    --popover: 40 6% 10%;
    --popover-foreground: 60 9% 98%;

    --primary: 42 91% 57%;
    --primary-foreground: 0 9% 2%;

    --secondary: 37 8% 19%;
    --secondary-foreground: 60 9% 98%;

    --muted: 44 7% 29%;
    --muted-foreground: 42 8% 49%;

    --accent: 42 71% 19%;
    --accent-foreground: 60 9% 98%;

    --destructive: 0 84% 60%;
    --destructive-foreground: 0 0% 98%;

    --border: 48 8% 12%;
    --input: 38 9% 18%;
    --ring: 42 91% 40%;
  }
}
 
@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
  }
}