.hexagonInner {
  /* insets the element from the edges of
     the element against which its positioned: */
  inset: 0px;
  position: absolute;
}

.hexagonOuter {
  /* allows one dimension to be set (here 'inline-size'/'width'),
     and the other dimension ('block-size'/'height') will be
     an equal width: */
  aspect-ratio: 1;
  /* logical property, equivalent to 'width' in the English -
     left-to-right - language: */
  inline-size: 100%;
  position: relative;
}

.hexagonHexagon {
  clip-path: polygon(0% 25%, 0% 75%, 50% 100%, 100% 75%, 100% 25%, 50% 0%);
}
